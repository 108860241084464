import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import AppURL from '../../api/AppURL';
import axios from 'axios';

class FooterAddress extends Component {
    constructor() {
        super();
        this.state = {
            contact_phone: "",
            contact_email: ""

        }

    }
    componentDidMount() {

        axios.post(AppURL.GetContactUs).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Contact'])[0];
                this.setState({
                    contact_title: JsonData['contact_title'],
                    contact_days: JsonData['contact_days'],
                    contact_time: JsonData['contact_time'],
                    contact_text: JsonData['contact_text'],
                    contact_address: JsonData['contact_address'],
                    contact_map: JsonData['contact_map']
                });
            }

        }).catch(error => {

        });

        axios.post(AppURL.GetPhoneLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Phone'])[0];
                this.setState({
                    contact_number: JsonData['contact_number']
                });
            }

        }).catch(error => {

        });

        axios.post(AppURL.GetEmailLimit).then(response => {
            let StatusCode = response.status;
            if (StatusCode == 200) {
                let JsonData = (response.data['Email'])[0];
                this.setState({
                    contact_email: JsonData['contact_email']
                });
            }

        }).catch(error => {

        });



    }
    render() {
        return (
            <Fragment>
                <section className="footer-contact-info-area">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} xl={12} xxl={12} sm={12} xs={12}>
                                <ul className="footer-contact-info clearfix">
                                    <li>
                                        <div className="single-footer-contact-info">
                                            <div className="inner">
                                                <div className="icon">
                                                    <span className="icon-global"></span>
                                                </div>
                                                <div className="text">

                                                    <div dangerouslySetInnerHTML={{ __html: this.state.contact_address }} />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="single-footer-contact-info">
                                            <div className="inner">
                                                <div className="icon">
                                                    <span className="icon-support1"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href={`tel:${this.state.contact_number}`} target="_blank">  {this.state.contact_number}</a><br /> <span>{this.state.contact_days}:</span> {this.state.contact_time}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="single-footer-contact-info">
                                            <div className="inner">
                                                <div className="icon">
                                                    <span className="icon-shipping-and-delivery"></span>
                                                </div>
                                                <div className="text">
                                                    <p><a href={`mailto:${this.state.contact_email}`} target="_blank">{this.state.contact_email}</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}

export default FooterAddress
