import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import AppURL from '../../../api/AppURL';
import axios from 'axios';
import HomeServicesLoading from '../../PlaceHolder/HomeServicesLoading';

class DesktopHomeServices extends Component {

    constructor() {
        super();
        this.state = {
            HomeServicesData: [],
            isLoading: "",
            mainDiv: "d-none"
        }
    }
    componentDidMount() {
        axios.post(AppURL.GetHomeServices).then(response => {

            this.setState({ HomeServicesData: response.data['Home Services'], isLoading: "d-none", mainDiv: "" });

        }).catch(error => {

        });


    }

    render() {

        const HomeServicesList = this.state.HomeServicesData;
        const HomeServicesView = HomeServicesList.map((HomeServicesList, i) => {
            return <Col lg={6} md={6} xl={6} xxl={6} sm={6} xs={6} className='text-center' key={i.toString()}>
                <div className='HomeServices-box-1'>
                    <span className="HomeServices-inner-box">
                        <img src={HomeServicesList.services_image} alt="" className="img-fluid rounded" />
                    </span>
                    <div>
                        <h5>{HomeServicesList.services_name}</h5>
                        <div dangerouslySetInnerHTML={{ __html: HomeServicesList.services_text }} />
                    </div>
                    <Link to={HomeServicesList.page_name} className="btn_one">{HomeServicesList.button_name}</Link>
                </div>
            </Col>




        })

        return (
            <Fragment>
                <Container>
                    <HomeServicesLoading isLoading={this.state.isLoading} />
                    <Row className={this.state.mainDiv}>


                        {HomeServicesView}

                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default DesktopHomeServices
